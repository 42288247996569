.firstphoto{
    display: block;
    width: 100%;
    height: 400px;
    margin-top: 10px;

}
@media screen and (max-width:625px){
.firstphoto{
    display: block;
    width: 100%;
    height: 150px;
    margin-top: 10px;
}
}