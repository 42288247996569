.decoreinfo{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: 30px;
    margin-bottom: 90px;
    align-items: center;
}
.decorep{
    display: flex;
    width: auto;
    height:300px;
    width: 300px;
    margin-left: 40px;
}
.decoreinfo1{
    margin: 40px;
    text-align: center;

}
.decoreprice{
    text-align: center;
    font-weight: 100;
}
.decoredsc{
    margin-left: 10px;
    font-size: larger;
}
@media screen and (max-width:625px){
    .decoreinfo{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        margin-top: 20px;
        margin-bottom: 40px;
        align-items: center;
    }
    .decorep{
        display: flex;
        width: auto;
        height:300px;
        width: 300px;
        margin-left: 5px;
    }
    .decoreinfo1{
        margin: 20px;
    }
    .decoreprice{
        text-align: center;
    }
    .decoredsc{
        text-align: center;
    }
}